<template>
<div>
  <div class="main">
    <div class="title">
        <span>會員登入</span>
    </div>

    <div class="title-msg">
        <span>請輸入登入帳號和密碼</span>
    </div>

    <form class="login-form" >
        <div class="input-content">
            <div>
                <input type="text" v-model="account" @keyup.enter="memlogin()" placeholder="使用者名稱"/>
            </div>
            <div style="margin-top: 16px">
                <input type="password" v-model="password" @keyup.enter="memlogin()" placeholder="登入密碼"/>
            </div>
        </div>

        <div style="text-align: center">
            <button type="button" class="enter-btn" @click="memlogin()">登入</button>
        </div>

    </form>
    </div>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import VueCookies from 'vue-cookies'
import axios from 'axios'
export default {
  data () {
    return {
      account: '',
      password: ''
    }
  },
  methods: {
    ...mapActions([
      'loginFunction'
    ]),
    memlogin () {
      if (this.account !== 'admin' && this.password !== 'admin') {
        const member = {
          account: this.account,
          password: this.password
        }
        this.loginFunction(member)
      }
    },
    memberCheck () {
      const memeber = JSON.parse(sessionStorage.getItem('login')) ? 1 : 0
      if (memeber && this.$router.history.current.path === '/') {
        this.$router.push({ path: '/index' }).catch(() => {})
      } else if (!memeber) {
        this.$router.push({ path: '/' }).catch(() => {})
      }
    },
    async postAuthorizeToken () {
      const token = VueCookies.get('Authorization')
      const access = await axios.post('/api/ems/auth/authorizeToken',
        {
          accessToken: token
        },
        {
        })
      if (access.data.success) {
        this.memberCheck()
      }
    }
  },
  computed: {
  },
  mounted () {
    this.postAuthorizeToken()
  }
}
</script>

<style lang="scss" scoped>
    @import '../assets/scss/Login.scss'
</style>
